<template>
  <div class="page">
    <form class="box" id="login-form">
      <a :href="getLandingUrl" class="logo"><img src="/img/KYC-PAY-logo.png" alt="Logo"></a>
      <div class="input-group">
        <label>Email</label>
        <input class="form-control" type="text" v-model="email">
        <span class="invalid-feedback" v-if="emailError.length > 0">{{ emailError }}</span>
      </div>
      <div class="input-group">
        <label>Password</label>
        <input class="form-control" type="password" v-model="password">
        <span class="invalid-feedback" v-if="passwordError.length > 0">{{ passwordError }}</span>
      </div>
      <button v-if="!load" class="btn submit" @click.prevent="login">Login</button>
      <a v-if="!load" class="forgot-password" @click.prevent="goToForgotPassword">Forgot password?</a>
      <div v-else class="load"><CircleLoad /></div>
    </form>
  </div>
</template>

<script>
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: "Login",
  components: {
    CircleLoad
  },
  data() {
    return {
      load: false,
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      twoFactorOnLogin: false
    }
  },
  computed: {
    getLandingUrl () {
      return process.env.VUE_APP_LANDING_URL
    }
  },
  methods: {
    async get2faSettings () {
      const that = this
      await axios.get(process.env.VUE_APP_BACKEND_API_URL + 'settings/customer-two-factor-on-login')
        .then(function ({ data }) {
          that.twoFactorOnLogin = (+data.customer_two_factor_on_login) ? true : false;
          const twoFactorOnLogin = that.twoFactorOnLogin
          that.$store.dispatch('setTwoFactorOnLogin', { twoFactorOnLogin });
          if (twoFactorOnLogin && data.code_is_not_empty) {
            that.$noty.success('Please, check your email - we send you two factor code.')
            that.$router.replace({ name: "LoginCode" })
          } else {
            that.$store.dispatch('removeTwoFactorOnLogin')
            that.$router.replace({ name: "Personal" })
          } 
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    async login() {
      this.load = true
      const that = this
      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'auth/login', {
          email: this.email,
          password: this.password,
        })
        .then(function (response) {
          if (response.data.error) {
            for (let key in response.data.errors) {
              if (key === 'email' && response.data.errors[key][0]) {
                that.emailError = response.data.errors[key][0]  
              } else if (key === 'password' && response.data.errors[key][0]) {
                that.passwordError = response.data.errors[key][0]
              }
            }
            return
          }
          const { token, expiresIn } = response.data
          that.$store.dispatch('setToken', { token, expiresIn })
          that.$store.dispatch('setUser')
          that.$store.dispatch('setContent')
          that.get2faSettings() 
        })
        .catch(function (error) {
          console.log(error);
        })
      this.load = false
    },
    goToForgotPassword () {
      this.$router.push({ name: 'ForgotPassword' })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &#login-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 2% auto 0;
      width: 100%;
      max-width: 30rem;
      background-color: #24292e;

      .logo {
        height: 103px;
        margin: 3rem auto;
      }

      .input-group {
        padding: .25rem 3rem;
        margin: .25rem 0 .75rem;
        width: 100%;

        @media (max-width: 576px) {
          padding: .25rem 1.5rem;
        }

        label {
          color: rgba(255, 255, 255, 0.5);
          padding-left: .5rem;
        }

        .form-control {
          width: 100%;
          height: 3.5rem;
          color: #ffffff;
          background-color: #1c2126;
          padding: .5rem 1rem .5rem 1.5rem;
          margin-top: .25rem;
          border: 1px solid #2f363d;
          border-radius: .75rem;
          box-shadow: 0 11px 23px rgba(0,0,0,.02);
          outline: none;
        }

        // input:-webkit-autofill {
        //   border: 0;
        //   -webkit-text-fill-color: #ffffff;
        //   background-color: #1c2126 !important;
        //   transition: background-color 5000s ease-in-out 0s;
        // }

        // @-webkit-keyframes autofill {
        //   to {
        //     background-color: #1c2126;
        //   }
        // }

        // input:-webkit-autofill {
        //   -webkit-animation-name: autofill;
        //   -webkit-animation-fill-mode: both;
        // }

        .invalid-feedback {
          width: 100%;
          padding-left: .5rem;
          font-size: 12px;
          color: #dc3545;
        }
      }

      .submit,
      .load {
        display: inline-flex;
        min-width: 10rem;
        min-height: 3.5rem;
        padding: .5rem 2.5rem;
        margin: 1.75rem auto 3rem;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 600;
        background-color: #62cdac;
        border: none;
        border-radius: .75rem;
      }

      .forgot-password {
        display: inline-flex;
        min-width: 10rem;
        min-height: 1.5rem;
        padding: 0.5rem 2.5rem;
        margin: 1.75rem auto 3rem;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 0.6rem;
        font-weight: 600;
        border: none;
        border-radius: 0.75rem;
        cursor: pointer;
      }

      .load {
        width: 10rem;
        height: 3rem;

        svg {
          width: 100%;
          height: 100%;
          animation:spin 1s linear infinite;

          @keyframes spin {
            100% {
              transform:rotate(360deg);
            }
          }
        }
      }
    }
  }
}
</style>
